import React, { useState } from 'react';
import './cardLineChart.scss';
import DashedLineChart from '../../components/graphics/dashedLineChart/dashedLineChart';

export default function CardLineChart(props) {
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    //setIsActive(!isActive);
    setIsActive(false);
  };

  const cardClassName = `${isActive ? props.bigCard ? 'cardLineChart big activeCard' : 'cardLineChart activeCard' : props.bigCard ? 'cardLineChart big' : 'cardLineChart'} ${props.className || ''}`;

  return (
    <div className={cardClassName} onClick={e => { toggleActive(); props.clickActive(e) }}>
      <div className="contentCard">
        <div className={props.bigCard ? 'graphicsCardLine big' : 'graphicsCardLine'}>
          <DashedLineChart data={props?.balanceDataGraphics ? props.balanceDataGraphics : null} onlyGraph={true} active={true} bigCard={props?.bigCard} />
        </div>

      </div>
    </div>
  )
}
