export const useLocalStorage = (keyName, defaultValue) => {
  window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
  return defaultValue;
};


export const getLocalStorage = (keyName) => {
  return window.localStorage.getItem(keyName);
};

export const removeLocalStorage = (keyName) => {
  window.localStorage.removeItem(keyName);
  return true;
};