import React from 'react';
import Hotkeys from 'react-hot-keys';
import './modalTicket.scss';

const ModalTicket = ({ children, isOpenModal, handleCloseModal}) => {

  const handleClose = () => {
    handleCloseModal(false);
  };

  const onKeyDown = (keyName, e, handle) => {
    e.preventDefault();
    switch(keyName){
      case "esc":handleClose();break;
      default: return false;
    }
    return false;
  };

  return (
    <div>
      {isOpenModal && (
        <Hotkeys 
          keyName="esc"
          onKeyDown={onKeyDown.bind(this)}
        >
          <div
            className="modal modal-fullscreen"
            style={{
              width: '100vw',
              height: '100vh',
            }}
          >
            {children}
          </div>
        </Hotkeys>
      )}
    </div>
  );
};

export default ModalTicket;