import './App.scss';
import React, {useState} from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import DefaultTemplate from './templates/default/pages/default';
import LoginPage from './modules/auth/pages/LoginPage';
import HomePage from './modules/home/pages/HomePage';
import AccountsPage from './modules/accounts/pages/AccountsPage';
import MinutesPage from './modules/minutes/pages/MinutesPage';
import DidsPage from './modules/dids/pages/DidsPage';
import InterconnectionsPage from './modules/interconnections/pages/InterconnectionsPage';
import BillingPage from './modules/billing/pages/BillingPage';
import SettingPage from './modules/setting/pages/SettingPage';
import { ProtectedRoute } from "./modules/auth/components/ProtectedRoute";

function App() {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [contentModal, setContentModal] = useState('contenido de la modal');

  const handleOpenModal = (content) => {
    setContentModal(content);
    setIsOpenModal(true);
  };

  return (
    <BrowserRouter>
    <Routes>
      <Route exact path="/conecta" element={<LoginPage />} />
      <Route exact path="/" element={<LoginPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/home"
        element={
          <ProtectedRoute>
            <DefaultTemplate
              isOpenModal={isOpenModal}
              handleCloseModal={() => setIsOpenModal(false)}
              contentModal={contentModal} 
              children={<HomePage isOpenModal={isOpenModal} handleOpenModal={handleOpenModal} handleCloseModal={() => setIsOpenModal(false)} />}
              />
          </ProtectedRoute>
        }
      />
      <Route
        path="/accounts"
        element={
          <ProtectedRoute>
            <DefaultTemplate 
              children={<AccountsPage />}
              />
          </ProtectedRoute>
        }
      />
      <Route
        path="/minutes"
        element={
          <ProtectedRoute>
            <DefaultTemplate 
              children={<MinutesPage />}
              />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dids"
        element={
          <ProtectedRoute>
            <DefaultTemplate 
              children={<DidsPage />}
              />
          </ProtectedRoute>
        }
      />
      <Route
        path="/interconnections"
        element={
          <ProtectedRoute>
            <DefaultTemplate 
              children={<InterconnectionsPage />}
              />
          </ProtectedRoute>
        }
      />
      <Route
        path="/billing"
        element={
          <ProtectedRoute>
            <DefaultTemplate 
              children={<BillingPage />}
              />
          </ProtectedRoute>
        }
      />
      <Route
        path="/setting"
        element={
          <ProtectedRoute>
            <DefaultTemplate 
              children={<SettingPage />}
              />
          </ProtectedRoute>
        }
      />
      <Route
        path="/support"
        element={
          <ProtectedRoute>
            <DefaultTemplate 
              children={<SettingPage />}
              />
          </ProtectedRoute>
        }
      />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
