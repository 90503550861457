import React from "react";
import Confirm from "./confirm.js";
import  './typeFormLogin.scss';
import { ReactComponent as  IconLine } from '../../images/line.svg';

export default function TypeFormLogin({ children, onSubmit, setFields, fields }) {
  children = [...children, <Confirm />];
 
  return (
    <React.Fragment>
      <form className="typeForm-container">
        {children[fields]}
        
        <div>
        {fields === 0 && (<div className="btn-container init">
              <div className="marginRight">
                <span onClick={() => setFields(1)} className='btn'>Log in</span>
                <p className="textInf">Welcome back</p>
              </div>
              <div className="marginCenter">
                <IconLine />
              </div>
              <div className="marginLeft">
                <span onClick={() => setFields(1)} className='btn'>Register</span>
                <p className="textInf">Welcome in</p>
              </div>
            </div>
          )}
          {fields === 1 && (
            <div className="link-container">
              <p><span className="linkConecta" onClick={() => setFields(0)}>Are you new to conecta?</span></p>
              <p><span className="linkRegister" onClick={() => setFields(0)}>Register here</span></p>
            </div>
          )}

          {fields === 2 && (
            <div className="link-container">
              <p><span  className="linkConecta" onClick={() => setFields(0)}>Didn’t work?</span></p>
              <p><span className="linkRegister" onClick={() => setFields(0)}>Forgot password?</span></p>
            </div>
          )}
          
        </div>
      </form>
    </React.Fragment>
  );
}
