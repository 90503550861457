import React from 'react';
import LoginForm from '../components/LoginForm';
import logo from '../../../logo.svg';
import logo2 from '../../../images/logo2.png';
// Figma https://www.figma.com/file/zyxviE8K2hML1QhJWxAQTC/Design-System-Conecta?type=design&node-id=4923-3015&mode=design&t=mzPjEhgOAA9GuqtN-0

const LoginPage = () => {
  let s_width = window.screen.width;
  // Implementación de la página de inicio de sesión
  return (
    <div className="login">

      { Number(s_width) <= 1000 ? <div className="noMobile">
        <div className="conten">
          <img src={logo2} className="logo" alt="logo" />
          <p>Dashboard Mobile en construcción</p>
        </div>
      </div> :
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p></p>
          <LoginForm />
        </header>
      }

      
    </div>
  );
};

export default LoginPage;