import React from 'react';
import { BsArrowDownSquare } from "react-icons/bs";
import './tableInfo.scss';



const TableInfo = ({dataTable, height}) => {

  return (
    <div className={`tableInfo h${height}` }>
      <div className="table">
        <div className="thead infoTableHeader">
          <div className="td">Latest case</div>
          <div className="td textRight">Time</div>
          <div className="td textRight">Amount</div>
        </div>
        <div className="tbody">
            {dataTable && dataTable.map((info, index) => (
              <div className="tr" key={`${index}-tr`}>
                <td className="groupCell"><BsArrowDownSquare /> <div className="CellTable"><p>{info?.cellPhone}</p><p><span className="colorGray">From: {info?.from}</span></p></div></td>
                <div className="td textRight colorGray">{info?.date}</div>
                <div className="td textRight">{info?.price}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
export default TableInfo;