import React, { useState } from 'react';
import './cardGraphics.scss';
import { FaArrowTrendUp } from "react-icons/fa6";
import { BsArrowRightShort } from "react-icons/bs";
import TinyBarChartPage from '../../components/graphics/tinyBarChart/tinyBarChart';

export default function CardGraphics(props) {
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    //setIsActive(!isActive);
    setIsActive(false);
  };

  const cardClassName = `${isActive ? props.bigCard ? 'cardGraphics big activeCard' : 'cardGraphics activeCard' : props.bigCard ? 'cardGraphics big' : 'cardGraphics'} ${props.className || ''}`;

  return (
    <div className={cardClassName} onClick={e => { toggleActive(); props.clickActive(e) }}>
      <div className="contentCard">
        <div className="flex_row">
          <p>  <FaArrowTrendUp /> {props?.data?.percentage}</p>
          <span> <BsArrowRightShort /></span>
        </div>
        <div className='titleCard'>
          <h3>{props?.data?.number}</h3>
          <span className={props.bigCard ? 'subtitle big' : 'subtitle'}>{props?.data?.title}</span>
        </div>
        <div className={props.bigCard ? 'graphicsCard big' : 'graphicsCard'}>
          <TinyBarChartPage data={props?.dataGraphics ? props.dataGraphics : null} onlyGraph={true} active={true} bigCard={props?.bigCard} />
        </div>

      </div>
    </div>
  )
}
