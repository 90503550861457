import React, { useState , useEffect } from 'react';
import { useTable, useFilters, useSortBy } from 'react-table';
import './table.scss';
import { BiEdit, BiXCircle, BiSolidSave} from "react-icons/bi";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { matchSorter } from "match-sorter";

// https://ncoughlin.com/posts/react-table-useFilter/


export function matchSorterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}


const TablaEditable = ({ data, columns }) => {
  const [tableData, setTableData] = useState(data);
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: ""
    }),
    []
  );
  const filterTypes = React.useMemo(
    () => ({
      rankedMatchSorter: matchSorterFn
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: tableData,
      defaultColumn,
      filterTypes
    },
    useFilters,
    useSortBy
  );
  
  useEffect(() => {
    //setTableData(data)
 
  });
  
  const handleEdit = (row) => {
    // Marcar la fila como editable
    const updatedData = [...tableData];
    console.log('row.original.id', row.id);
    if (row.id !== -1) {
      updatedData[row.id].isEditing = true;
      setTableData(updatedData);
    }
  };

  const handleSave = (row) => {
    // Guardar los cambios de edición
    const updatedData = [...tableData];
    if (row.id  !== -1) {
      updatedData[row.id].isEditing = false;
      updatedData[row.id].nombre = row.values.nombre; // Actualiza el nombre editado
      // Puedes realizar una llamada a una API o guardar los cambios en tu fuente de datos aquí
      setTableData(updatedData);
    }
  };

  const handleCancel = (row) => {
    // Cancelar la edición
    const updatedData = [...tableData];
    if (row.id !== -1) {
      updatedData[row.id].isEditing = false;
      setTableData(updatedData);
    }
  };

  const handleChange= (e, row, routeColumn) => {
    // Cancelar la edición
    const updatedData = [...tableData];
    const partsColumn = routeColumn.split(".");
    updatedData[row.id][partsColumn[0]][partsColumn[1]] = e.target.value;
    setTableData(updatedData);
  };

  return (
    <div className='moduleTable'>
      <table {...getTableProps()} className="tabla">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div style={{display: 'flex'}}>
                    {column.render("Header")}
                    <span style={{margin: '3px 3px'}}>
                      {column.isSorted ? (column.isSortedDesc ? <AiOutlineArrowDown /> : <AiOutlineArrowUp />) : ""}
                    </span>
                  </div>
                  {/* Render the columns filter UI */}
                  <div className='header'>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
              <th>Acciones</th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className='trbody'>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>
                      {row.original.isEditing ? (
                        <input
                          type="text"
                          value={row.values[cell.column.id]}
                          onChange={(e) => {
                            handleChange(e, row, cell.column.id);
                          }}
                        />
                      ) : (
                        cell.render('Cell')
                      )}
                    </td>
                  );
                })}
                <td>
                  {row.original.isEditing ? (
                    <>
                      <button onClick={() => handleSave(row)}><BiSolidSave /></button>
                      <button onClick={() => handleCancel(row)}><BiXCircle /></button>
                    </>
                  ) : (
                    <button onClick={() => handleEdit(row)}><BiEdit /></button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TablaEditable;