import React, { useState } from 'react';
import { motion } from "framer-motion";
import { BsArrowRightShort, BsPlusLg } from "react-icons/bs";

import  './login.scss';

export default function EmailFields({ setInput, setFields }) {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);


  const handleEmail = (e) => {
    e.preventDefault();
    setInput('email',email)
    // don't remember from where i copied this code, but this works.
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if ( re.test(email) ) {
      setEmailError(false);
      console.log(email, 'email correcto');
      setFields(2)
    }
    else {
      // invalid email, maybe show an error to the user.
      setEmailError(true);
      console.log(email, 'error de email');
    }
    
  };
  return (
    <div className="login-container" autoComplete="off">
      <motion.div
        className="col-md-6 offset-md-3"
        initial={{ y: "50vh" }}
        animate={{ y: 0 }}
        transition={{ stiffness: 150 }}
      >
        <h1>Hey there, welcome to conecta</h1>
        <p>Enter your email or username to continue</p>
        <div className="btn-container">
          <span className="icon"><BsPlusLg /></span>
          <input id="email" className={emailError ? 'error' : '' } label="Email" type="email" onChange={(e) => setEmail(e.target.value)}  />
          <button className="btnSend" onClick={handleEmail} ><BsArrowRightShort /></button>
          
        </div>
      </motion.div>
    </div>
  );
}