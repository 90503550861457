import React, { useState } from 'react';
import './cardLocation.scss';
import { BsArrowDownRightCircleFill } from "react-icons/bs";
import RoundPhoto from '../roundPhoto/roundPhoto';

export default function CardLocation(props) {
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  const cardClassName = `${isActive ? 'cardLocation activeCard' : 'cardLocation'} ${props.className || ''}`;

  return (
    <div className={cardClassName} onClick={e => {toggleActive();}}>
        <div className="contentCard">
            <div className='titleCard'>
                <RoundPhoto name={props?.data?.name} image={props?.data?.image} />
                <span className="subtitle">{props?.data?.title}</span>
            </div>
            <div className="flex_row">
              <p> Followed by {props?.data?.city}</p>
              <span> <BsArrowDownRightCircleFill /></span>
            </div>
        </div>
    </div>
  )
}
