import React, { useState, useEffect } from 'react';
import axios from 'axios'; //necesarion para mockear la api
import  '../../../mocks/apiHomeMock'; //necesarion para mockear la api
import  './setting.scss';
import Greeting from '../../../components/greeting/greeting';

const SettingPage = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {
    try {
      // Utiliza mock para simular las respuestas de la API
      const response = await axios.get('/api/accounts');
      console.log('Respuesta de la API:', response.data);
      setData(response.data);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };
  return (
    <div className="ContainerBody SettingContainer">

        <div className="TwoColumns">
          <div className='Columns1'>
            <div className="row-body">
              <div className="row1">
                <div className="SubContent">
                  <Greeting name={data?.userName}/>
                  <p className='bodyS' >Let’s take a look at your conecta lines today</p>
                </div>
                <div> Setting here ... </div>
              </div>
            
            </div>

          </div>  
        </div>

    </div>
  );
};

export default SettingPage;