// Content.js

import React from 'react';
import  '../styles/content.scss';

export const Content = ({ children }) => {
  return (
    <div className="Content ">
      {children}
    </div>
  );
};