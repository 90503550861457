import React, {useContext } from 'react';
import { motion } from 'framer-motion';
import "./carSlideTop.scss";
import { AiOutlineClose } from 'react-icons/ai';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { DIDContext } from '../../templates/default/components/Header';

const CardSlideTop = () => {

  const {didAddisVisible, setDIDisVisible} = useContext(DIDContext)
  //const [currentSlide, setCurrentSlide] = useState(0);
  const currentSlide = 0;

  const cardVariants = {
    hidden: { x: '110%', display: 'none' },
    visible: { x: 0, display: 'block' }
  };

  return (
    <div className='containerSlideTop'>
      <motion.div
        initial="hidden"
        animate={didAddisVisible ? "visible" : "hidden"}
        variants={cardVariants}
        className='cardInformation'>
        <div className="cardTopRight">
          <AiOutlineClose className="closeButton" onClick={() => setDIDisVisible(false)} />
        </div>
        <div className='headerCardTop'>
          <button className="addCardNew"><IoMdAddCircleOutline /></button>
          <h3>Your new line</h3>
          <p>Tap and fill your line details.</p>
        </div>
        <div className="slide">
          <div className={`cardSlide ${currentSlide === 1 ? 'active' : ''}`}>
            <h4>Slide 1/2</h4>
            <div className="contentSlide">
                <div className="topContentSlide">
                    <h5>Houston, TX</h5>
                    <p>United States</p>
                </div>
                <br/>
                <span className="flagCard">🇺🇸 </span>
                <br/>
                <div className="phoneNumbers">
                    <small>Ring to</small>
                    <p className="strongNumber">+1 123 456 789</p>
                    <p className="strongNumber">+1 987 654 321</p>
                </div>
            </div>
          </div>
        </div>
        <div className="footerCard">
          <button>Continue to payment</button>
            <p><small>Includes credit card convenience fee*</small></p>
        </div>
      </motion.div>
    </div>
  );
};

export default CardSlideTop;
