// Sidebar.js
import React, { useState , useEffect} from 'react';
import logo from '../../../images/logo.png';
import logo2 from '../../../images/logo2.png';
import products2 from '../../../images/products2.png';
import { ReactComponent as  IconBtnLeft } from '../../../images/btnLeft.svg';
import { ReactComponent as  IconBtnRight } from '../../../images/btnRight.svg';

import { Link } from "react-router-dom";

import IconHome from '../../../images/icons/IconHomeDefault.png';
import IconHomeHover from '../../../images/icons/IconHomeHover.png';
import IconHomeActive from '../../../images/icons/IconHomeActivo.png';
import IconSupport from '../../../images/icons/IconSupportDefault.png';
import IconSupportHover from '../../../images/icons/IconSupportHover.png';
import IconSupportActive from '../../../images/icons/IconSupportActivo.png';
import IconSettings from '../../../images/icons/IconSettingsDefault.png';
import IconSettingsHover from '../../../images/icons/IconSettingsHover.png';
import IconSettingsActive from '../../../images/icons/IconSettingsActivo.png';
import IconBilling from '../../../images/icons/IconBillingDefault.png';
import IconBillingHover from '../../../images/icons/IconBillingHover.png';
import IconBillingActive from '../../../images/icons/IconBillingActivo.png';
import IconID from '../../../images/icons/IconIDDefault.png';
import IconIDHover from '../../../images/icons/IconIDHover.png';
import IconIDActive from '../../../images/icons/IconIDActivo.png';
import IconAccount from '../../../images/icons/IconAccountDefault.png';
import IconAccountHover from '../../../images/icons/IconAccountHover.png';
import IconAccountActive from '../../../images/icons/IconAccountActivo.png';
import IconMinutes from '../../../images/icons/IconMinutesDefault.png';
import IconMinutesHover from '../../../images/icons/IconMinutesHover.png';
import IconMinutesActive from '../../../images/icons/IconMinutesActivo.png';

import  '../styles/sidebar.scss';


export function Sidebar() {
  const [showText, setShowText] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
  const [hovered, setHovered] = useState(null);
  const [settingEnURL, setsettingEnURL] = useState(null);

  useEffect(() => {
    obtenerRutaActual();
  }, []);

  const handleToggleText = () => {
    setShowText(!showText);
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const handleOnclick = (page) => {
    setsettingEnURL(page);
  };

  const handleMouseEnter = (index, page) => {
    setHovered(index);
  };

  const handleMouseLeave = () => {
    setHovered(null);
  };

  const obtenerRutaActual = () => {
    const rutaCompleta = window.location.href; // Obtenemos la URL completa
    const partesRuta = rutaCompleta.split('/'); // Dividimos la URL por "/"
    const rutaSetting = partesRuta[partesRuta.length - 1]; // Obtenemos el último segmento de la URL (en este caso, "setting")\
    setsettingEnURL(rutaSetting);
    return rutaSetting;
  };
  let s_height = window.screen.height;
  let s_width = window.screen.width;
  return (
    <aside className={`Sidebar br1 ${sidebarCollapsed ? 'collapsed' : ''} s_h${s_height} s_w${s_width}`}>
       <div className="groupBtn">
        {!showText ?
         <IconBtnLeft onClick={handleToggleText} className="btnLeft" /> :
         <IconBtnRight onClick={handleToggleText} className="btnRight" />
         }
        
       
      </div>
      <div className="caja1">
        <nav className='nav1'>
          <img src={sidebarCollapsed ? logo : logo2} className="logo" alt="logo" />
          <ul>
            <li className={settingEnURL === "home" ? "active": ""} onMouseEnter={() => handleMouseEnter(1, 'home')}  onMouseLeave={handleMouseLeave}>
              <Link to='/home' onClick={() => handleOnclick('home')}>
                <div className="groupLi">
                  {hovered === 1  ? <img src={IconHomeHover} alt="Home hover" width="23"  /> : settingEnURL === "home" ? <img src={IconHomeActive} alt="Home" width="23"  /> : <img src={IconHome} alt="Home" width="23"  />}
                  {showText && <span className="txt">Home</span>}
                </div>
              </Link>
            </li>
            <li className={settingEnURL === "accounts" ? "active": ""} onMouseEnter={() => handleMouseEnter(2, 'accounts')}  onMouseLeave={handleMouseLeave}>
              <Link to='/accounts' onClick={() => handleOnclick('accounts')}>
                <div className="groupLi">
                  {hovered === 2  ? <img src={IconAccountHover} alt="Accounts hover" width="23"  /> : settingEnURL === "accounts" ? <img src={IconAccountActive} alt="Account" width="23"  /> : <img src={IconAccount} alt="Account" width="23"  />}
                  {showText && <span className="txt">Accounts</span>}
                </div>
              </Link>
            </li>

            <li className={settingEnURL === "minutes" ? "active": ""} onMouseEnter={() => handleMouseEnter(3, 'minutes')}  onMouseLeave={handleMouseLeave}>
              <Link to='/minutes' onClick={() => handleOnclick('minutes')}>
                <div className="groupLi">
                  {hovered === 3  ? <img src={IconMinutesHover} alt="minutes hover" width="23"  /> : settingEnURL === "minutes" ? <img src={IconMinutesActive} alt="minutes" width="23"  /> : <img src={IconMinutes} alt="minutes" width="23"  />}
                  {showText && <span className="txt">Minutes</span>}
                </div>
              </Link>
            </li>

            <li className={settingEnURL === "dids" ? "active": ""} onMouseEnter={() => handleMouseEnter(4, 'dids')}  onMouseLeave={handleMouseLeave}>
              <Link to='/dids' onClick={() => handleOnclick('dids')}>
                <div className="groupLi" >
                  {hovered === 4  ? <img src={IconIDHover} alt="ID active" width="23"  /> : settingEnURL === "dids" ? <img src={IconIDActive} alt="ID" width="23"  /> : <img src={IconID} alt="ID" width="23"  />}
                  {showText && <span className="txt">DISs</span>}
                </div>
                </Link>
            </li>

            

            <li className={settingEnURL === "billing" ? "active": ""} onMouseEnter={() => handleMouseEnter(5, 'billing')}  onMouseLeave={handleMouseLeave}>
              <Link to='/billing' onClick={() => handleOnclick('billing')}>
                <div className="groupLi">
                  {hovered === 5 ? <img src={IconBillingHover} alt="Billing active" width="24" height="20" /> : settingEnURL === "billing" ? <img src={IconBillingActive} alt="Billing" width="24" height="20"   /> : <img src={IconBilling} alt="Billing" width="24" height="20"   />}
                  {showText && <span className="txt">Billing</span>}
                </div>
              </Link>
            </li>

            <li className={settingEnURL === "setting" ? "active": ""} onMouseEnter={() => handleMouseEnter(6,'setting')}  onMouseLeave={handleMouseLeave}>
              <Link to='/setting' onClick={() => handleOnclick('setting')}>
                <div className="groupLi">
                  {hovered === 6 ? <img src={IconSettingsHover} alt="Settings active" width="23"  /> : settingEnURL === "setting" ? <img src={IconSettingsActive} alt="Settings" width="23"  /> : <img src={IconSettings} alt="Settings" width="23"  />}
                  {showText && <span className="txt">Setting</span>}
                </div>
              </Link>
            </li>
          </ul>  
        </nav>

        <nav className='nav2'>
          {showText && 
              <div className="promotional">
                <img src={products2} alt="promotional" />
              </div>
            }
            
            <ul >
              <li className={settingEnURL === "support" ? "active": ""} onMouseEnter={() => handleMouseEnter(7, 'support')}  onMouseLeave={handleMouseLeave}>
                <Link to='/support' onClick={() => handleOnclick('support')}>
                  <div className="groupLi">
                    {hovered === 7 ? <img src={IconSupportHover} alt="Support active" width="23"  /> : settingEnURL === "support" ? <img src={IconSupportActive} alt="Support" width="23"  /> : <img src={IconSupport} alt="Support" width="23"  />}
                    {showText && <span className="txt">Support</span>}
                  </div>
                </Link>
              </li>

            </ul>
        </nav>
      </div>
      
      
    </aside>
  );
}