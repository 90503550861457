import React, { useState } from 'react';
import { motion } from "framer-motion";
import { BsArrowRightShort } from "react-icons/bs";

import  './login.scss';

export default function PasswordField({ setInput, onSubmit, ErrorMsj }) {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);

  const handleChange = (event) => {
    setPassword(event.target.value)
    setInput('password',event.target.value)
  };

  const handleEmail = (e) => {
    e.preventDefault();
    if (password.length > 0) {
      setPasswordError(false);
      onSubmit(e)
    }
    else {
      // invalid email, maybe show an error to the user.
      setPasswordError(true);
      console.log(password, 'error de password');
    }
    
  };
  return (
    <div className="login-container" autoComplete="off">
      <motion.div
        className="col-md-6 offset-md-3"
        initial={{ y: "50vh" }}
        animate={{ y: 0 }}
        transition={{ stiffness: 150 }}
      >
        <h1>Hey there, welcome to conecta</h1>
        <p>Enter your email or username to continue</p>
        <div className="btn-container">
          <input id="password" className={passwordError ? 'error password' : 'password' } label="Password" type="password" onChange={handleChange}  />
          <button className="btnSend" onClick={handleEmail} ><BsArrowRightShort /></button>
        </div>
        <p className='error' style={{ color: 'red' }}>
          {ErrorMsj}
        </p>
      </motion.div>
    </div>
  );
}