import React, { useState } from 'react';
import './cardInfoProfile.scss';
import { BsArrowDownRightCircleFill } from "react-icons/bs";

export default function CardInfoProfile(props) {
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  const cardClassName = `${isActive ? 'cardInfoProfile activeCard' : 'cardInfoProfile'} ${props.className || ''}`;

  return (
    <div className={cardClassName} onClick={e => {toggleActive(); props.clickActive(e)}}>
        <div className="contentCard">
            <div className='titleCard'>
                <h3>{props?.data?.number}</h3>
                <span className="subtitle">{props?.data?.title}</span>
            </div>
            <div className="flex_row">
              <p> {props?.data?.percentage}</p>
              <span> <BsArrowDownRightCircleFill /></span>
            </div>
        </div>
    </div>
  )
}
