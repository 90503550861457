import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import  './stackedAreaChart.scss';
const data = [
  {
    name: 'Ene 01',
    users: 4000,
    sessions: 2400,
  },
  {
    name: 'Ene 02',
    users: 4100,
    sessions: 1400,
  },
  {
    name: 'Ene 03',
    users: 4500,
    sessions: 3400,
  },
  {
    name: 'Ene 04',
    users: 4800,
    sessions: 5400,
  },
  {
    name: 'Ene 05',
    users: 4200,
    sessions: 400,
  },
  {
    name: 'Ene 06',
    users: 3500,
    sessions: 3000,
  },
  {
    name: 'Ene 07',
    users: 5080,
    sessions: 4400,
  },
];

export default class StackedAreaChart extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/stacked-area-chart-ix341';

  render() {
    return (
      <div className={`Graphics GraphicsLineChart only ${this.props.active ? 'active2' : ''} ${this.props.bigCard ? 'big' : ''}`} >
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Area type="monotone" dataKey="users" stackId="1" stroke="#00ADD0" fill="#979698" />
            <Area type="monotone" dataKey="sessions" stackId="1" stroke="#00ADD0" fill="#181818" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
