import React, { useState, useEffect } from 'react';
import axios from 'axios'; //necesarion para mockear la api
import  '../../../mocks/apiHomeMock'; //necesarion para mockear la api
import  './interconnections.scss';

const InterconnectionsPage = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      // Utiliza mock para simular las respuestas de la API
      const response = await axios.get('/api/accounts');
      console.log('Respuesta de la API:', response.data);
      setData(response.data);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };
  return (
    <div className="InterconnectionsContainer">

      <h1>Good night, {data?.name}</h1>

      <div className="TwoColumns">
          <div className='Columns1'>
            <div className="SubContent">
              <h2>Your Interconnections</h2>
            
            </div>
           
           
          
          </div>
          
      </div>
      
    </div>
  );
};

export default InterconnectionsPage;