import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { ReactComponent as DropdownIcon } from '../../../images/dropdown_icon.svg';
import { ReactComponent as DropdownIconReversed } from '../../../images/dropdown_icon_reversed.svg';
import IconAccount from '../../../images/icons/IconAccountDefault.png';
import IconAccountHover from '../../../images/icons/IconAccountHover.png';
import IconSettings from '../../../images/icons/IconSettingsDefault.png';
import IconSettingsHover from '../../../images/icons/IconSettingsHover.png';

import { ReactComponent as LogoutIcon } from '../../../images/logout.svg';
import "../../../components/cardSlideTop/carSlideTop.scss";
//import { DIDContext } from './Header';

import '../styles/selector.scss';

const Selector = () => {

  //const {setDIDisVisible} = useContext(DIDContext)
  const [showText, setShowText] = useState(false);
  const [dropdownCollapsed, setDropdownCollapsed] = useState(true);
  const [hovered, setHovered] = useState(null);

  const handleToggleText = () => {
    setShowText(!showText);
    setDropdownCollapsed(!dropdownCollapsed);
  };

  const handleMouseEnter = (index) => {
    setHovered(index);
  };

  const handleMouseLeave = () => {
    setHovered(null);
  };

  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      // Utiliza mock para simular las respuestas de la API
      const response = await axios.get('/api/home-data');
      console.log('Respuesta de la API:', response.data);
      setData(response.data);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  return (
    <div className='SelectorContainer'>
      <div className='MenuContainer' >
        <aside className={`Selector br1 ${dropdownCollapsed ? 'collapsed' : 'expanded'}`} onClick={handleToggleText}>
          <div className='InfoColumn br1'>
            <div className='Name'>
              <div id='Username'>
                {data?.userName}.
              </div>
            </div>
            <div className='DropdownMenu'>
              {!showText ?
                <DropdownIcon className="downButton" /> :
                <DropdownIconReversed className="reversedButton" />
              }
            </div>
          </div>
        </aside>
        {/*<button onClick={() => setDIDisVisible(true)} className='buttonHeaderAdd br1'>
        <IoMdAddCircleOutline/>
        </button>*/}
      </div>
      <div className={`ProfileSelectorList ${dropdownCollapsed ? 'collapsed' : 'expanded'}`}>
        <nav>
          <ul>
            <li>
              <a href="/profile" onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={handleMouseLeave}>
                <div className="groupLi">
                  {hovered === 1 ? <img src={IconAccountHover} alt="Accounts hover" width="23" /> : <img src={IconAccount} alt="Account" width="23" />}
                  {showText && <span className="txt">Profile</span>}
                </div>
              </a>
            </li>
            <li>
              <a href="/profile" onMouseEnter={() => handleMouseEnter(2)} onMouseLeave={handleMouseLeave}>
                <div className="groupLi">
                  {hovered === 2 ? <img src={IconSettingsHover} alt="Settings active" width="23" /> : <img src={IconSettings} alt="Settings" width="23" />}
                  {showText && <span className="txt">Settings</span>}
                </div>
              </a>
            </li>
            <li>
              <a href="/profile" onMouseEnter={() => handleMouseEnter(3)} onMouseLeave={handleMouseLeave}>
                <div className="groupLi">
                  {hovered === 3 ? <LogoutIcon fill="white" /> : <LogoutIcon fill="white" className='stroke' />}
                  {showText && <span className="txt">Log out</span>}
                </div>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Selector
