import React from "react";
import ConfirmTicket from "./confirmTicket.js";
import  './typeFormTicket.scss';

export default function TypeFormTicket({ children, fields, handleCloseModal }) {
  children = [...children, <div className="link-container textarea-container"><ConfirmTicket /></div>];
 
  return (
    <React.Fragment>
      <form className="typeForm-container">
        {children[fields]}

      
        <div className="link-container">
          <p><span className="linkConecta" onClick={() => handleCloseModal()}>Are you done?</span></p>
          <p><span className="linkRegister" onClick={() => handleCloseModal()}>Go back to dashboard</span></p>
        </div>
      

      </form>
      
    </React.Fragment>
  );
}
