import React, { useState, useEffect } from 'react';
import axios from "axios"; //necesarion para mockear la api
import  '../../../mocks/apiHomeMock'; //necesarion para mockear la api
import  './dids.scss';
import TablaEditable from '../../../components/tablaEditable/Table';
import Greeting from '../../../components/greeting/greeting';
import { BiSearchAlt2 } from "react-icons/bi";


// filters
import { DropdownFilter, TextSearchFilter } from "../../../components/tablaEditable/Filters";

const DidsPage = () => {
   /* 
    - Columns is a simple array right now, but it will contain some logic later on. It is recommended by react-table to memoize the columns data
    - Here in this example, we have grouped our columns into two headers. react-table is flexible enough to create grouped table headers
  */
    const columns = [
      {
        Header: "Name",
        accessor: "show.name",
        Filter: TextSearchFilter,
        filter: "rankedMatchSorter"
      },
      {
        Header: "Type",
        accessor: "show.type",
        Filter: DropdownFilter,
      },
      {
        Header: "Language",
        accessor: "show.language",
      },
      {
        Header: "Genre(s)",
        accessor: "show.genres",
      },
      {
        Header: "Runtime",
        accessor: "show.runtime",
      },
      {
        Header: "Status",
        accessor: "show.status",
      }
    ];

  // data state to store the TV Maze API data. Its initial value is an empty array
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = async () => {
    const response = await axios.get('/api/didsTable');
    console.log('Respuesta de la API:', response.data);
    return response.data;
  };

  
  useEffect(() => {
    const filterData = async (data) => {
      return await data.filter((item) => {
        console.log('item', searchTerm);
        return item.show.name.toLowerCase().includes(searchTerm.toLowerCase()) ? item : item.show.name.toLowerCase().includes(searchTerm.toLowerCase());
      });
    };
  
    // Obtenemos los datos de la API.
    fetchData().then(async (data) => {
       // Filtramos los datos por el término de búsqueda.
       return data.tabla2 ? setData(await filterData(data?.tabla2)) : [];
    });
 
  }, [searchTerm]);


  

  return (
    <div className="ContainerBody DidsContainer">

        <div className="TwoColumns">
          <div className='Columns1'>
            <div className="row-body">
              <div className="row1">
                <div className="SubContent">
                  <Greeting name={data?.userName}/>
                  <p className='bodyS' >This is the page 1/8 in a total of 40 items</p>
                </div>
                <div className="contentTable">
                  { data.length !== 0 &&
                    <TablaEditable 
                      data={data} 
                      columns={columns}
                    />
                  }
                  
                </div>
                <div className="search">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <span className="icon"><BiSearchAlt2 /></span>
                </div>
                <div className="pagination">
                  
                </div>
              </div>
            
            </div>

          </div>  
        </div>

    </div>
  );
};

export default DidsPage;