import React, { useState } from 'react';
import './cardAreaChart.scss';
import StackedAreaChart from '../../components/graphics/stackedAreaChart/stackedAreaChart';

export default function CardAreaChart(props) {
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    //setIsActive(!isActive);
    setIsActive(false);
  };

  const cardClassName = `${isActive ? props.bigCard ? 'cardAreaChart big activeCard' : 'cardAreaChart activeCard' : props.bigCard ? 'cardAreaChart big' : 'cardAreaChart'} ${props.className || ''}`;

  return (
    <div className={cardClassName} onClick={e => { toggleActive(); props.clickActive(e) }}>
      <div className="contentCard">
        <div className={props.bigCard ? 'graphicsCardLine big' : 'graphicsCardLine'}>
          <StackedAreaChart data={props?.balanceDataGraphics ? props.balanceDataGraphics : null} onlyGraph={true} active={true} bigCard={props?.bigCard} />
        </div>

      </div>
    </div>
  )
}
