import React, { useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import { ReactComponent as  IconSelected } from '../../images/selected.svg';
import './dateRangePickerSimple.scss';
import moment from 'moment'; 

const DateRangePickerSimple = (props) => {
  const [dateRange, setDateRange] = useState('');

  const handleEvent = (event, picker) => {
    //console.log(picker.startDate.format('YYYY-MM-DD'), 'startDate');
    //console.log(picker.endDate.format('YYYY-MM-DD'), 'endDate');
  };

  const handleCallback = (start, end) => {
    console.log(start.format('YYYY-MM-DD'), 'startDate');
    console.log(end.format('YYYY-MM-DD'), 'endDate');
  };

  const handleDateChange = (event, picker) => {
    setDateRange(`${picker.startDate.format('MM D, YY')} - ${picker.endDate.format('MM D, YYYY')}`);
  };

  const initialSettings = {
    startDate: moment().subtract(7, 'days'), // Últimos 7 días
    endDate: moment(),
  };  

  return (
    <div className='dateRangePickerConten'>
      <DateRangePicker onEvent={handleEvent} onCallback={handleCallback} onApply={handleDateChange} initialSettings={initialSettings} >
        <input type="text" value={dateRange ? dateRange : `${initialSettings.startDate.format('MM D, YY')} - ${initialSettings.endDate.format('MM D, YY')}`} className="form-control" />
      </DateRangePicker>
      <IconSelected />
    </div>
    
  );
};

export default DateRangePickerSimple;