import { useLocalStorage, removeLocalStorage, getLocalStorage } from "./useLocalStorage";

class AuthService {
  login(email, password) {
    return new Promise((resolve, reject) => {
      // Lógica de autenticación
      if (password === '123456') {
        // Autenticación exitosa
        let expiresAt = JSON.stringify(
          new Date().getTime()
        );
        console.log(expiresAt, "expiresAt");
        useLocalStorage('expiresAt', expiresAt);
        resolve({ success: true, message: 'Inicio de sesión exitoso' });
      } else {
        // Autenticación fallida
        removeLocalStorage('expiresAt');
        reject({ success: false, message: 'Credenciales inválidas' });
      }
    });
  }

  register(user) {
    // Lógica para registrar un nuevo usuario con los datos proporcionados
    // ...

  }

  isAuthenticated2() {
    return new Promise((resolve, reject) => {
      // Check whether the current time is past the
      // access token's expiry time
      let expiresAt = JSON.parse(getLocalStorage('expiresAt'));
      if(!expiresAt){
        reject({ success: false, message: 'Credenciales inválidas' });
      }
      resolve({ success: expiresAt, message: 'Inicio de sesión exitoso' });

    });
  }

  isAuthenticated() {
    // Check whether the current time is past the access token's expiry time
    let expiresAt = JSON.parse(getLocalStorage('expiresAt'));
  
    if (!expiresAt) {
      return false;
    }
  
    return true;
  }
  
  
}

export default AuthService;