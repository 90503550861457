import React, { useState, useEffect } from 'react';
import axios from 'axios'; //necesarion para mockear la api
import '../../../mocks/apiHomeMock'; //necesarion para mockear la api
import './accounts.scss';
import CardInfoProfile from '../../../components/cardInfoProfile/cardInfoProfile';
import CardProfile from '../../../components/cardProfile/cardProfile';
import CardGraphics from '../../../components/cardGraphics/cardGraphics';
import CardMainCard from '../../../components/cardMainCard/cardMainCard';
import CardLocation from '../../../components/cardLocation/cardLocation';
import Greeting from '../../../components/greeting/greeting';

const AccountsPage = () => {
  const [data, setData] = useState(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const clickActive = () => {
    setActive(!active);
  };

  const fetchData = async () => {
    try {
      // Utiliza mock para simular las respuestas de la API
      const response = await axios.get('/api/accounts');
      console.log('Respuesta de la API:', response.data);
      setData(response.data);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };
  return (
    <div className="ContainerBody AccountsContainer">

      <div className="TwoColumns">
        <div className='Columns1'>
          <div className="row-body">
            <div className="row1">
              <div className="SubContent">
                <Greeting name={data?.name} />
                <p className='bodyS' >Follow your main business levers in one place.</p>
              </div>

              <div className="contentCardInfo">
                <CardProfile data={{ name: data?.name, lastName: data?.lastName, nickName: data?.nickName, image: data?.image }} />

                <CardGraphics
                  clickActive={clickActive}
                  data={{ number: data?.dataCard.number, percentage: data?.dataCard.percentage, title: data?.dataCard.title }}
                  dataGraphics={data?.balanceDataGraphics}
                  bigCard={false}
                />

                <CardMainCard
                  clickActive={clickActive}
                  data={{ number: '•317', percentage: '04/27', title: 'Main card' }}
                />

              </div>


              <div className="SubContent">
                <h2 className='headings1'>Let’s take a closer look</h2>
                <p className='bodyS'>A closer look to your experience with us.</p>
              </div>

              <div className="contentCardInfo">
                {data?.dataCard2 && data.dataCard2.map((info, index) => (
                  <CardInfoProfile
                    key={`${index}-${info.number}-${info.percentage}`}
                    clickActive={clickActive}
                    data={{ number: info.number, percentage: info.percentage, title: info.title }}
                  />
                ))}

                <CardLocation data={data?.dataLocation} />
              </div>

            </div>

          </div>

        </div>

      </div>
    </div>
  );
};

export default AccountsPage;
