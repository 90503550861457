import React, { useEffect, useState } from 'react';

const Greeting = (props) => {
  const [greet, setGreet] = useState('');

  useEffect(() => {
    const getGreeting = () => {
      let currentTime  = new Date().getHours();
      console.log('currentTime', currentTime);
      if (currentTime  >= 0 && currentTime  < 12) {
        setGreet('Good morning');
      } else if (currentTime  >= 12 && currentTime  < 18) {
        setGreet('Good afternoon');
      } else {
        setGreet('Good night');
      }
    };

    getGreeting();
  }, []);

  return (
      <h1 className='headings1'>{greet}, {props?.name ? props.name : ''}</h1>
  );
};

export default Greeting;