import React, { useState, useEffect } from 'react';
import axios from 'axios'; //necesarion para mockear la api
import '../../../mocks/apiHomeMock'; //necesarion para mockear la api
import './home.scss';
import UsefulInsights from '../../../components/usefulInsights/UsefulInsights';
import CardInfo from '../../../components/cardInfo/cardInfo';
import CardGraphics from '../../../components/cardGraphics/cardGraphics';
import TableInfo from '../../../components/table/tableInfo';
import DateRangePickerSimple from '../../../components/dateRangePickerSimple/dateRangePickerSimple';
import Greeting from '../../../components/greeting/greeting';
import FloatingButton from '../../../components/floatingButton/floatingButton';
//import { abbreviatedformat } from '../../../general'; 

const HomePage = (props) => {
  const [data, setData] = useState(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const clickActive = () => {
    setActive(!active);
  };

  const fetchData = async () => {
    try {
      // Utiliza mock para simular las respuestas de la API
      const response = await axios.get('/api/home-data');
      console.log('Respuesta de la API:', response.data);
      setData(response.data);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };
  return (
    <div className="ContainerBody">



      <div className="TwoColumns">
        <div className='Columns1'>
          <div className="row-body">
            <div className="row1">
              <div className="SubContent">
                <Greeting name={data?.userName} />
                <p className='bodyS' >Let’s take a look at your conecta lines today</p>
              </div>
              <div className="contentCardInfo">
                {data?.dataCard && data.dataCard.map((info, index) => (
                  <CardInfo
                    key={`${index}-${info.number}-${info.percentage}`}
                    clickActive={clickActive}
                    data={{ number: info.number, percentage: info.percentage, title: info.title, link: info.link }}
                  />
                ))}
              </div>
            </div>


            <div className='row1'>
              <div className="SubContent">
                <h2 className='headings1'>Let’s take a closer look</h2>
                <p className='bodyS'>A closer look to your experience with us.</p>
              </div>
              <div className="ContenSelected">
                <DateRangePickerSimple />
              </div>

              <div className="contentCardInfo2">

                <CardGraphics
                  clickActive={clickActive}
                  data={{ number: data?.dataCardBalance.number, percentage: data?.dataCardBalance.percentage, title: data?.dataCardBalance.title }}
                  dataGraphics={data?.balanceDataGraphics}
                  bigCard={true}

                />

                <CardGraphics
                  clickActive={clickActive}
                  data={{ number: data?.dataCardCalls.number, percentage: data?.dataCardCalls.percentage, title: data?.dataCardCalls.title }}
                  dataGraphics={data?.balanceDataGraphics}
                  bigCard={true}
                />


                <TableInfo dataTable={data?.dataTable} />
              </div>

            </div>
          </div>

          <div className='row-footer'>
            <div className="SubContent">
              <h2 className='headings1'>Useful insights</h2>
              <p className='bodyS'>Follow payments and other daily happenings.</p>
            </div>
            <UsefulInsights />
          </div>

        </div>
      </div>
      <FloatingButton isOpenModal={props.isOpenModal} handleOpenModal={props.handleOpenModal} handleCloseModal={props.handleCloseModal} />
    </div>
  );
};

export default HomePage;
