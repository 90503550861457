import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import  './dashedLineChart.scss';
const data = [
  {
    name: 'Jan 01',
    min: 4000,
    sales: 2400,
  },
  {
    name: 'Jan 02',
    min: 3000,
    sales: 1398,
  },
  {
    name: 'Jan 03',
    min: 2000,
    sales: 9800,
  },
  {
    name: 'Jan 04',
    min: 2780,
    sales: 3908,
  },
  {
    name: 'Jan 05',
    min: 1890,
    sales: 4800,
  },
  {
    name: 'Jan 06',
    min: 2390,
    sales: 3800,
  },
  {
    name: 'Jan 07', 
    min: 3490,
    sales: 4300,
  },
];

export default class DashedLineChart extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/dashed-line-chart-dxwzg';

  render() {
    return (
      <div className={`Graphics GraphicsLineChart only ${this.props.active ? 'active2' : ''} ${this.props.bigCard ? 'big' : ''}`} >
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="sales" stroke="#979698" strokeDasharray="5 5" />
            <Line type="monotone" dataKey="min" stroke="#979698" strokeDasharray="3 4 5 2" />
          </LineChart>
        </ResponsiveContainer>
    </div>
      
    );
  }
}
