import React, { useState, useEffect } from 'react';
import axios from 'axios'; //necesarion para mockear la api
import '../../../mocks/apiHomeMock'; //necesarion para mockear la api
import './minutes.scss';
//import TableInfo from '../../../components/table/tableInfo';
import CardInfo from '../../../components/cardInfo/cardInfo';
import CardGraphics from '../../../components/cardGraphics/cardGraphics';
import CardLineChart from '../../../components/cardLineChart/cardLineChart';
import CardAreaChart from '../../../components/cardAreaChart/cardAreaChart';
import Greeting from '../../../components/greeting/greeting';

const MinutesPage = () => {
  const [data, setData] = useState(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const clickActive = () => {
    setActive(!active);
  };

  const fetchData = async () => {
    try {
      // Utiliza mock para simular las respuestas de la API
      const response = await axios.get('/api/minutesData');
      console.log('Respuesta de la API:', response.data);
      setData(response.data);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  return (
    <div className="ContainerBody MinutesContainer">

      <div className="TwoColumns">
        <div className='Columns1'>
          <div className="row-body">
            <div className="row1">
              <div className="SubContent">
                <Greeting name={data?.userName} />
                <p className='bodyS' >What have you been up to?</p>
              </div>
              <div className="contentCardInfo">
                {data?.dataCard && data.dataCard.map((info, index) => (
                  <CardInfo
                    key={`${index}-${info.number}-${info.percentage}`}
                    clickActive={clickActive}
                    data={{ number: info.number, percentage: info.percentage, title: info.title }}
                  />
                ))}
              </div>


              <div className='Insights'>
                <div className="SubContent">
                  <h2 className='headings1'>Let’s take a closer look</h2>
                  <p className='bodyS'>A closer look to your experience with us.</p>
                </div>
                <div className="contentCardInfo">
                  <CardGraphics
                    clickActive={clickActive}
                    data={{ number: data?.dataCardBalance.number, percentage: data?.dataCardBalance.percentage, title: data?.dataCardBalance.title }}
                    dataGraphics={data?.balanceDataGraphics}
                    bigCard={true}
                    onlyGraph={true}

                  />

                  <CardLineChart bigCard={true} clickActive={clickActive} />
                </div>
              </div>

              <div className='Insights'>
                <div className="SubContent">
                  <h2 className='headings1'>Let’s take a closer look</h2>
                  <p className='bodyS'>A closer look to your experience with us.</p>
                </div>
                <div className="contentCardInfo">
                  <CardAreaChart bigCard={true} clickActive={clickActive} />
                </div>
              </div>



            </div>

          </div>

        </div>
      </div>

    </div>
  );
};

export default MinutesPage;
