import React, { useState } from 'react';
import TypeFormTicket from "./typeFormTicket";
import TexareaFields from "./textarea";
import BlankStart from "../typeForm/login/blankStart";


import  './formTicket.scss';

const FormTicket = (props) => {
  const [fields, setFields] = useState(0);
  const [textarea, setTextarea] = useState('');

  const setInput = (type, value) => {
    switch (type) {
      case 'textarea':
        setTextarea(value);
        break;
      default:
        break;
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault(); // Evita que se actualice la página al enviar el formulario
    console.log(textarea, 'handleLogin');
    setFields(2);
  };

  return (
    <div className="auth-container FormTicket">
      <TypeFormTicket onSubmit={handleSubmit}  fields={fields} setFields={setFields} handleCloseModal={props.handleCloseModal}  >
        <TexareaFields setInput={setInput} onSubmit={handleSubmit} setFields={setFields}  />
        <BlankStart />
      </TypeFormTicket>
    </div>
  );
};

export default FormTicket;