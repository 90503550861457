import { Navigate } from "react-router-dom";
import AuthService from "../services/AuthService";

export const ProtectedRoute = ({ children }) => {
  const authService = new AuthService();

  if (!authService.isAuthenticated()) {
    // user is not authenticated
    console.log('user is not authenticated', authService.isAuthenticated());
    return <Navigate to="/" />;
  }
  return children;
};