import React, { PureComponent } from 'react';
import { BarChart, Bar, Tooltip, XAxis, ResponsiveContainer } from 'recharts';

import { ReactComponent as  IconArrowStatistics } from '../../../images/arrow_statistics.svg';
import  './tinyBarChart.scss';

const dataDemo = [
  {
    name: 'M-A',
    uv: 1,
  },
  {
    name: 'M-A',
    uv: 1,
  },
  {
    name: 'M-A',
    uv: 1,
  },
  {
    name: 'M-A',
    uv: 1,
  },
  {
    name: 'M-A',
    uv: 1,
  },
  {
    name: 'M-A',
    uv: 1,
  },
  {
    name: 'M-A',
    uv: 1,
  },
  {
    name: 'M-A',
    uv: 1,
  },
  {
    name: 'M-A',
    uv: 1,
  },
  {
    name: 'M-A',
    uv: 1,
  },
  {
    name: 'M-A',
    uv: 1,
  },
  {
    name: 'M-A',
    uv: 1,
  },
  {
    name: 'M-A',
    uv: 1,
  },
  {
    name: 'M-A',
    uv: 1,
  },
  {
    name: 'M-A',
    uv: 1,
  },
  {
    name: 'M-A',
    uv: 10,
  },
];

export class TinyBarChart extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/tiny-bar-chart-35meb';

  render() {
    return (
      <ResponsiveContainer >
        <BarChart  data={this.props?.data ? this.props.data : dataDemo}>
          <Bar dataKey="balance" fill="#979698" />
          <XAxis dataKey="name" />
          { this.props?.bigCard &&
           <Tooltip />
          }
          
        </BarChart>
      </ResponsiveContainer>
    );
  }
}


export default class TinyBarChartPage extends PureComponent {

  constructor(props) {
    super(props)
    const { data } = this.props;
    this.state = {
      data: data,
      filterActive: false,
      filterActiveType: null
    }

    this.filterClick = this.filterClick.bind(this);
  }

  filterClick(type) {
    let filteredData = this.props?.data;
    let filterActive = false;
    const currentDate = new Date();
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    switch (type) {
      case 'Y':
        if (this.state.filterActiveType !== type) {
          // Filtrar los datos por año
          filteredData = this.props?.data?.filter(item => {
            const itemYear = new Date(item.date).getFullYear();
            return itemYear === currentYear;
          });
          filterActive = true;
        }
        break;
      case 'M':
        if (this.state.filterActiveType !== type) {
          // Filtrar los datos por mes
          filteredData = this.props?.data?.filter(item => {
            const itemMonth = new Date(item.date).getMonth();
            return itemMonth === currentMonth;
          });
          filterActive = true;
        }
        break;
      case 'W':
        if (this.state.filterActiveType !== type) {
          // Filtrar los datos por semana
          filteredData = this.props?.data?.filter(item => {
            const itemDate = new Date(item.date);
            const oneWeekAgo = new Date().setDate(currentDate.getDate() - 7);
            return itemDate > oneWeekAgo;
          });
          filterActive = true;
        }
        break;
      default:
        break;
    }
    

    this.setState(state => ({
      filterActive: filterActive,
      data: filteredData,
      filterActiveType: filterActive ? type : null
    }));
  }
  
  render() {
    return (
      <div>
        { this.props?.onlyGraph &&
          <div className={`Graphics TinyBarChart only ${this.props.active ? 'active2' : ''} ${this.props.bigCard ? 'big' : ''}`} >
            <TinyBarChart data={this.props.active ? this.state?.data ? this.state?.data : this.props?.data : null } />
          </div>
        }
        { !this.props?.onlyGraph &&
          <div className={`Graphics TinyBarChart ${this.props.active ? 'active' : ''}`} >
          <div className='Title'>
            <h3>Metric</h3>
            <div className='contemUl'>
              <ul>
                <li>MTD</li>
                <li onClick={() => this.filterClick('M')} className={`${this.state.filterActiveType === 'M' ? 'active' : ''}`}>M</li>
                <li>WTD</li>
                <li onClick={() => this.filterClick('W')} className={`${this.state.filterActiveType === 'W' ? 'active' : ''}`}>W</li>
                <li onClick={() => this.filterClick('Y')} className={`${this.state.filterActiveType === 'Y' ? 'active' : ''}`}>Y</li>
              </ul>
            </div>
            
          </div>
          <div className='Number'>
            <span>00.00</span>
          </div>
          
          <div className='Icon'>
            <IconArrowStatistics />
            <p>+00% rate</p>
          </div>
          
          <TinyBarChart data={this.props.active ? this.state?.data ? this.state?.data : this.props?.data : null } bigCard={this.props?.bigCard ? this.props?.bigCard : false} />
        </div>
        }
      </div>
    );
  }
}
