import React, { useState, useEffect } from 'react';
import axios from 'axios'; //necesarion para mockear la api
import  '../../../mocks/apiHomeMock'; //necesarion para mockear la api
import  './billing.scss';
//import TableInfo from '../../../components/table/tableInfo';
import CardInfo from '../../../components/cardInfo/cardInfo';
import CardMainCard from '../../../components/cardMainCard/cardMainCard';
import Greeting from '../../../components/greeting/greeting';

const BillingPage = () => {
  const [data, setData] = useState(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const clickActive = () => {
    setActive(!active);
  };

  const fetchData = async () => {
    try {
      // Utiliza mock para simular las respuestas de la API
      const response = await axios.get('/api/minutesData');
      console.log('Respuesta de la API:', response.data);
      setData(response.data);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  return (
    <div className="ContainerBody BillingContainer">

        <div className="TwoColumns">
          <div className='Columns1'>
            <div className="row-body">
              <div className="row1">
                <div className="SubContent">
                  <Greeting name={data?.userName}/>
                  <p className='bodyS' >Check out your calls</p>
                </div>
                <div className="contentCardInfo">
                  {data?.dataCard && data.dataCard.map((info, index) => (
                    <CardInfo
                      key={`${index}-${info.number}-${info.percentage}`}
                      clickActive={clickActive}
                      data={{ number: info.number, percentage: info.percentage, title: info.title }}
                    />
                  ))}
                </div>
                
                <div className='Insights'>
                  <div className="SubContent">
                    <h2 className='headings1'>Let’s take a closer look</h2>
                    <p className='bodyS'  >A closer look to your experience with us.</p>
                  </div>
                  <div className="contentCardInfo">
                      <CardMainCard
                        clickActive={clickActive}
                        data={{ number: '•317', percentage: '04/27', title: 'Main card' }}
                      />

                      <CardMainCard
                        clickActive={clickActive}
                        data={{ number: '••••', percentage: 'MM/YY', title: 'Add card' }}
                      />
                  </div>
                </div>


              </div>
            
            </div>

          </div>  
        </div>

    </div>
  );
};
export default BillingPage;