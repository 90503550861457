import React, {useState} from 'react';
import './floatingButton.scss';
import BtnPlusMenu from '../../images/icons/btnPlusMenu.png';
import BtnPlusMenuOpen from '../../images/icons/btnPlusMenuOpen.png';
import BtnMenu1 from '../../images/icons/menu1.png';
import BtnMenu2 from '../../images/icons/menu2.png';
import BtnMenu3 from '../../images/icons/menu3.png';
import BtnMenu4 from '../../images/icons/menu4.png';
import BtnMenu5 from '../../images/icons/menu5.png';
import FormTicket from '../formTicket/formTicket';

export default function FloatingButton(props) {
  const [checked, setChecked] = useState(false);
  return (
    
        <nav className="menu">
          <div className={checked ? "conten checked" : "conten"}>
            <input type="checkbox" checked={checked} onChange={(e) => setChecked(e.target.checked)} className="menu-open" name="menu-open" id="menu-open"/>
            <label className="menu-open-button" for="menu-open">
              <img className="hamburger-1" src={BtnPlusMenu} alt="open" /> 
              <img className="hamburger-2" src={BtnPlusMenuOpen} alt="open" /> 
            </label>
           
            
            <span onClick={() => props.handleOpenModal(<FormTicket handleCloseModal={props.handleCloseModal} />)} className="menu-item"> <img  src={BtnMenu5} alt="menu5" /> </span>
            <span className="menu-item"> <img  src={BtnMenu1} alt="menu1" /> </span>
            <span className="menu-item"> <img  src={BtnMenu2} alt="menu2" /> </span>
            <span className="menu-item"> <img  src={BtnMenu3} alt="menu3" /> </span>
            <span className="menu-item"> <img  src={BtnMenu4} alt="menu4" /> </span>
          </div>
        </nav>
    
  )
}
