import React from 'react';
import { AiOutlineCamera } from "react-icons/ai";

const roundPhoto = ({ name, image }) => {
  // Función para obtener las dos letras iniciales del name
  const obtenerIniciales = (name) => {
    const words = name.split(' ');
    if (words.length > 1) {
      return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase();
    } else {
      return name.slice(0, 2).toUpperCase();
    }
  };

  return (
    <div
      style={{
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        background: '#0B090C',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '24px',
        fontWeight: 'bold',
        border: '3px solid #707070',
      }}
    >
      {image ? (
        <img
          src={image}
          alt="Profile"
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            objectFit: 'cover',
          }}
        />
      ) : (
        !name ? <AiOutlineCamera fill="#FFFFFF" /> :
        <span>{obtenerIniciales(name)}</span>
      )}
    </div>
  );
};

export default roundPhoto;