import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

const mockHome = new MockAdapter(axios);

// Simula una respuesta exitosa desde la API
mockHome.onGet('/api/home-data').reply(200, {
  respuesta: 'Respuesta de la API mockeada',
  userName: 'Johnny',
  insights: 30,
  activeCases: 4,
  balanceDataGraphics: [
    {
      name: 'Jan',
      balance: 50,
      date: '2023-01-13',
    },
    {
      name: 'Feb',
      balance: 60,
      date: '2023-03-13',
    },
    {
      name: 'Mar',
      balance: 70,
      date: '2023-03-13',
    },
    {
      name: 'Apr',
      balance: 80,
      date: '2023-03-13',
    },
    {
      name: 'May',
      balance: 80,
      date: '2023-03-13',
    },
    {
      name: 'Jun',
      balance: 70,
      date: '2023-03-13',
    },
    {
      name: 'Jul',
      balance: 60,
      date: '2023-03-13',
    },
    {
      name: 'Aug',
      balance: 50,
      date: '2023-03-13',
    },
    {
      name: 'Sep',
      balance: 50,
      date: '2023-03-13',
    },
    {
      name: 'Oct',
      balance: 30,
      date: '2023-03-13',
    },
    {
      name: 'Nov',
      balance: 20,
      date: '2023-03-13',
    },
    {
      name: 'Dec',
      balance: 10,
      date: '2023-03-13',
    }
  ],
  dataCardBalance: { number: '$1450', percentage: '+23', title: 'Balance' },
  dataCardCalls: { number: '21', percentage: '+13', title: 'Calls' },
  dataCard: [{ number: '765K', percentage: false, title: 'Card ID', link: '/accounts' }, { number: '$23K', percentage: false, title: 'Balance', link: '/accounts' }, { number: '3', percentage: false, title: 'DIDs', link: '/dids' }, { number: '125', percentage: false, title: 'Calls', link: '/minutes' }, { number: '5', percentage: false, title: 'Tickets', link: '/home' }],
  dataCard2: [{ number: '58.38K', percentage: '+23', title: 'Minutes' }, { number: '23.43k', percentage: '-53', title: 'DIDs' }, { number: '$43,5k', percentage: '83%', title: 'Revenue' }],
  dataTable: [{ cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' }],
});

// Simula una respuesta con error desde la API
mockHome.onGet('/api/home-data/error').reply(500, {
  error: 'Error de la API mockeada',
});


// Simula una respuesta exitosa desde la API
mockHome.onGet('/api/accounts').reply(200, {
  userName: 'Johnny',
  name: 'Johnny',
  lastName: 'Bosche',
  nickName: '@Johnnyuser',
  image: 'https://lh3.googleusercontent.com/a-/AD_cMMSf3jBWpdlmPtWa55MGYzffTjC9SsJKFzfQSOu1ZnEy8cM=s40-p',
  dataCard2: [{ number: 'Ad. ventures', percentage: 'Company' }, { number: 'luis.serna @gmail.com', percentage: 'Email' }],
  dataLocation: { image: 'https://th.bing.com/th/id/R.a2fec36c135094b7a3169a5530bfe07f?rik=RsM0TJtGLIk1gA&pid=ImgRaw&r=0', city: 'Mexico', title: 'Main location' },
  dataCard: { number: '$1450', percentage: '+23', title: 'Balance' },
  balanceDataGraphics: [
    {
      name: 'Jan',
      balance: 50,
      date: '2023-01-13',
    },
    {
      name: 'Feb',
      balance: 60,
      date: '2023-03-13',
    },
    {
      name: 'Mar',
      balance: 70,
      date: '2023-03-13',
    },
    {
      name: 'Apr',
      balance: 80,
      date: '2023-03-13',
    },
    {
      name: 'May',
      balance: 80,
      date: '2023-03-13',
    },
    {
      name: 'Jun',
      balance: 70,
      date: '2023-03-13',
    },
    {
      name: 'Jul',
      balance: 60,
      date: '2023-03-13',
    },
    {
      name: 'Aug',
      balance: 50,
      date: '2023-03-13',
    },
    {
      name: 'Sep',
      balance: 50,
      date: '2023-03-13',
    },
    {
      name: 'Oct',
      balance: 30,
      date: '2023-03-13',
    },
    {
      name: 'Nov',
      balance: 20,
      date: '2023-03-13',
    },
    {
      name: 'Dec',
      balance: 10,
      date: '2023-03-13',
    }
  ],
});

// Simula una respuesta con error desde la API
mockHome.onGet('/api/home-data/error').reply(500, {
  error: 'Error de la API mockeada',
});



// Simula una respuesta exitosa desde la API
mockHome.onGet('/api/minutesData').reply(200, {
  respuesta: 'Respuesta de la API minutesData mockeada',
  userName: 'Johnny',
  insights: 30,
  activeCases: 4,
  balanceDataGraphics: [
    {
      name: 'Jan',
      balance: 50,
      date: '2023-01-13',
    },
    {
      name: 'Feb',
      balance: 60,
      date: '2023-03-13',
    },
    {
      name: 'Mar',
      balance: 70,
      date: '2023-03-13',
    },
    {
      name: 'Apr',
      balance: 80,
      date: '2023-03-13',
    },
    {
      name: 'May',
      balance: 80,
      date: '2023-03-13',
    },
    {
      name: 'Jun',
      balance: 70,
      date: '2023-03-13',
    },
    {
      name: 'Jul',
      balance: 60,
      date: '2023-03-13',
    },
    {
      name: 'Aug',
      balance: 50,
      date: '2023-03-13',
    },
    {
      name: 'Sep',
      balance: 50,
      date: '2023-03-13',
    },
    {
      name: 'Oct',
      balance: 30,
      date: '2023-03-13',
    },
    {
      name: 'Nov',
      balance: 20,
      date: '2023-03-13',
    },
    {
      name: 'Dec',
      balance: 10,
      date: '2023-03-13',
    }
  ],
  dataLocation: { image: 'https://th.bing.com/th/id/R.a2fec36c135094b7a3169a5530bfe07f?rik=RsM0TJtGLIk1gA&pid=ImgRaw&r=0', city: 'Mexico', title: 'Main location' },
  dataCardBalance: { number: '$1450', percentage: '+23', title: 'Balance' },
  dataCardCalls: { number: '21', percentage: '+13', title: 'Calls' },
  dataCard: [{ number: '3104', percentage: false, title: 'Card ID', link: '/accounts' }, { number: '21', percentage: false, title: 'Total Calls', link: '/accounts' }, { number: 'Today', percentage: false, title: 'Data range', link: '/dids' }],
  dataCard2: [{ number: '58.38K', percentage: '+23', title: 'Minutes' }, { number: '23.43k', percentage: '-53', title: 'DIDs' }, { number: '$43,5k', percentage: '83%', title: 'Revenue' }],
  dataTable: [{ cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' }
  ],
});

// Simula una respuesta con error desde la API
mockHome.onGet('/api/minutesData/error').reply(500, {
  error: 'Error de la API minutesData mockeada',
});



// Simula una respuesta exitosa desde la API
mockHome.onGet('/api/dids').reply(200, {
  respuesta: 'Respuesta de la API dids mockeada',
  userName: 'Johnny',
  insights: 30,
  activeCases: 4,
  balanceDataGraphics: [
    {
      name: 'M-A',
      uv: 50,
      date: '2023-07-13',
    },
    {
      name: 'M-A',
      uv: 14,
      date: '2023-07-14',
    },
    {
      name: 'M-A',
      uv: 23,
      date: '2023-07-15',
    },
    {
      name: 'M-A',
      uv: 81,
      date: '2023-07-16',
    },
    {
      name: 'M-A',
      uv: 35,
      date: '2023-07-17',
    },
    {
      name: 'M-A',
      uv: 10,
      date: '2023-07-18',
    },
    {
      name: 'M-A',
      uv: 71,
      date: '2023-06-18',
    },
    {
      name: 'M-A',
      uv: 14,
      date: '2023-06-18',
    },
    {
      name: 'M-A',
      uv: 45,
      date: '2023-06-18',
    },
    {
      name: 'M-A',
      uv: 50,
      date: '2021-01-01',
    },
    {
      name: 'M-A',
      uv: 11,
      date: '2021-01-01',
    },
    {
      name: 'M-A',
      uv: 32,
      date: '2021-01-01',
    },
    {
      name: 'M-A',
      uv: 54,
      date: '2021-01-01',
    },
    {
      name: 'M-A',
      uv: 25,
      date: '2021-01-01',
    },
    {
      name: 'M-A',
      uv: 15,
      date: '2021-01-01',
    },
    {
      name: 'M-A',
      uv: 10,
      date: '2021-01-01',
    },
  ],
  dataCardBalance: { number: '$1450', percentage: '+23', title: 'Balance' },
  dataCardCalls: { number: '21', percentage: '+13', title: 'Calls' },
  dataCard: [{ number: '23', percentage: false, title: 'DIDs', link: '/dids' }],
  dataTable: [{ cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' },
  { cellPhone: '3050022922', from: '21-5776-6002 ', date: '24 Mar, 4:20 PM', price: '0.03 USD' }
  ],
});

// Simula una respuesta con error desde la API
mockHome.onGet('/api/dids/error').reply(500, {
  error: 'Error de la API dids mockeada',
});


mockHome.onGet('/api/didsTable').reply(200, {
  respuesta: 'Respuesta de la API dids mockeada',
  userName: 'Johnny333333',
  insights: 30,
  activeCases: 4,
  tabla2: [
    {
      "score": 0.9083116,
      "show": {
        "id": 62693,
        "url": "https://www.tvmaze.com/shows/62693/snow",
        "name": "Snow",
        "type": "Scripted",
        "language": "English",
        "genres": [],
        "status": "In Development",
        "runtime": null,
        "averageRuntime": null,
        "premiered": null,
        "ended": null,
        "officialSite": null,
        "schedule": {
          "time": "",
          "days": []
        },
        "rating": {
          "average": null
        },
        "weight": 98,
        "network": {
          "id": 8,
          "name": "HBO",
          "country": {
            "name": "United States",
            "code": "US",
            "timezone": "America/New_York"
          },
          "officialSite": "https://www.hbo.com/"
        },
        "webChannel": null,
        "dvdCountry": null,
        "externals": {
          "tvrage": null,
          "thetvdb": null,
          "imdb": null
        },
        "image": {
          "medium": "https://static.tvmaze.com/uploads/images/medium_portrait/455/1138518.jpg",
          "original": "https://static.tvmaze.com/uploads/images/original_untouched/455/1138518.jpg"
        },
        "summary": "<p>A <i>Game of Thrones</i> sequel centered on Jon Snow.</p>",
        "updated": 1680649628,
        "_links": {
          "self": {
            "href": "https://api.tvmaze.com/shows/62693"
          }
        }
      }
    },
    {
      "score": 0.86173785,
      "show": {
        "id": 10412,
        "url": "https://www.tvmaze.com/shows/10412/snow",
        "name": "Snow",
        "type": "Scripted",
        "language": "English",
        "genres": [
          "Comedy",
          "Family",
          "Fantasy"
        ],
        "status": "Ended",
        "runtime": 120,
        "averageRuntime": 120,
        "premiered": "2004-12-13",
        "ended": "2008-12-14",
        "officialSite": "http://abcfamily.go.com/movies/listing/snow",
        "schedule": {
          "time": "20:00",
          "days": [
            "Sunday"
          ]
        },
        "rating": {
          "average": null
        },
        "weight": 39,
        "network": {
          "id": 26,
          "name": "Freeform",
          "country": {
            "name": "United States",
            "code": "US",
            "timezone": "America/New_York"
          },
          "officialSite": "https://www.freeform.com/"
        },
        "webChannel": null,
        "dvdCountry": null,
        "externals": {
          "tvrage": null,
          "thetvdb": null,
          "imdb": null
        },
        "image": {
          "medium": "https://static.tvmaze.com/uploads/images/medium_portrait/35/88041.jpg",
          "original": "https://static.tvmaze.com/uploads/images/original_untouched/35/88041.jpg"
        },
        "summary": "<p><b>Snow</b> is an American Christmas-themed film starring Tom Cavanagh and Ashley Williams that premiered in 2004 on the ABC television network, and was also shown on the ABC Family cable network later the same year. It was written by Rich Burns and directed by Alex Zamm. Since 2004, Snow has become a staple on ABC Family's annual 25 Days of Christmas programming block. The sequel, <i>Snow 2: Brain Freeze</i>, was released on December 14, 2008 as part of ABC Family's 25 Days of Christmas. The main cast from the first film reprise their roles in the sequel.</p>",
        "updated": 1670595447,
        "_links": {
          "self": {
            "href": "https://api.tvmaze.com/shows/10412"
          },
          "previousepisode": {
            "href": "https://api.tvmaze.com/episodes/537594"
          }
        }
      }
    },
    {
      "score": 0.70112634,
      "show": {
        "id": 63510,
        "url": "https://www.tvmaze.com/shows/63510/black-snow",
        "name": "Black Snow",
        "type": "Scripted",
        "language": "English",
        "genres": [
          "Drama",
          "Crime",
          "Mystery"
        ],
        "status": "To Be Determined",
        "runtime": null,
        "averageRuntime": 52,
        "premiered": "2023-01-01",
        "ended": null,
        "officialSite": "https://www.stan.com.au/watch/black-snow",
        "schedule": {
          "time": "",
          "days": []
        },
        "rating": {
          "average": 7.1
        },
        "weight": 98,
        "network": null,
        "webChannel": {
          "id": 64,
          "name": "Stan",
          "country": {
            "name": "Australia",
            "code": "AU",
            "timezone": "Australia/Sydney"
          },
          "officialSite": "https://www.stan.com.au/"
        },
        "dvdCountry": null,
        "externals": {
          "tvrage": null,
          "thetvdb": 422279,
          "imdb": "tt21263178"
        },
        "image": {
          "medium": "https://static.tvmaze.com/uploads/images/medium_portrait/438/1096696.jpg",
          "original": "https://static.tvmaze.com/uploads/images/original_untouched/438/1096696.jpg"
        },
        "summary": "<p>In 1994, seventeen-year-old Isabel Baker was murdered. The crime shocked the small town of Ashford and devastated Isabel's Australian South Sea Islander community. The case was never solved, the killer never found. But in 2019, the opening of a time capsule unearths a secret that puts cold-case Detective James Cormack on the trail of the killer.</p>",
        "updated": 1690718554,
        "_links": {
          "self": {
            "href": "https://api.tvmaze.com/shows/63510"
          },
          "previousepisode": {
            "href": "https://api.tvmaze.com/episodes/2458987"
          }
        }
      }
    },
    {
      "score": 0.66921544,
      "show": {
        "id": 22810,
        "url": "https://www.tvmaze.com/shows/22810/snow-lotus",
        "name": "Snow Lotus",
        "type": "Scripted",
        "language": "Korean",
        "genres": [
          "Drama",
          "Fantasy",
          "Romance"
        ],
        "status": "Ended",
        "runtime": 60,
        "averageRuntime": 61,
        "premiered": "2015-11-11",
        "ended": "2015-11-11",
        "officialSite": "http://tv.sbs.co.kr/LucidDream/",
        "schedule": {
          "time": "23:00",
          "days": [
            "Wednesday"
          ]
        },
        "rating": {
          "average": null
        },
        "weight": 50,
        "network": {
          "id": 127,
          "name": "SBS",
          "country": {
            "name": "Korea, Republic of",
            "code": "KR",
            "timezone": "Asia/Seoul"
          },
          "officialSite": null
        },
        "webChannel": null,
        "dvdCountry": null,
        "externals": {
          "tvrage": null,
          "thetvdb": 315706,
          "imdb": "tt5646756"
        },
        "image": {
          "medium": "https://static.tvmaze.com/uploads/images/medium_portrait/84/211489.jpg",
          "original": "https://static.tvmaze.com/uploads/images/original_untouched/84/211489.jpg"
        },
        "summary": "<p>A fantasy melodrama that traverses dreams, reality and the time-space continuum. It is the story of two lovers who cross paths again in the dream world, not in real life, after a thousand years. Lee Soo Hyun is the CEO of a gaming company who uses his own dreams as the basis for an online game called Lucid Dream. When one of his part time employees, on leave from art school, reads Soo Hyun's script, she finds herself thrown into confusion as it starts coinciding with her own dreams where she also meets Ma Moon Jae, a man who existed a thousand years ago...</p>",
        "updated": 1505170337,
        "_links": {
          "self": {
            "href": "https://api.tvmaze.com/shows/22810"
          },
          "previousepisode": {
            "href": "https://api.tvmaze.com/episodes/987596"
          }
        }
      }
    },
    {
      "score": 0.66271555,
      "show": {
        "id": 37282,
        "url": "https://www.tvmaze.com/shows/37282/snow-flower",
        "name": "Snow Flower",
        "type": "Scripted",
        "language": "Korean",
        "genres": [
          "Drama",
          "Romance"
        ],
        "status": "Ended",
        "runtime": 60,
        "averageRuntime": 60,
        "premiered": "2006-11-20",
        "ended": "2007-01-09",
        "officialSite": null,
        "schedule": {
          "time": "21:55",
          "days": [
            "Monday",
            "Tuesday"
          ]
        },
        "rating": {
          "average": null
        },
        "weight": 44,
        "network": {
          "id": 127,
          "name": "SBS",
          "country": {
            "name": "Korea, Republic of",
            "code": "KR",
            "timezone": "Asia/Seoul"
          },
          "officialSite": null
        },
        "webChannel": null,
        "dvdCountry": null,
        "externals": {
          "tvrage": null,
          "thetvdb": 281773,
          "imdb": null
        },
        "image": {
          "medium": "https://static.tvmaze.com/uploads/images/medium_portrait/460/1150889.jpg",
          "original": "https://static.tvmaze.com/uploads/images/original_untouched/460/1150889.jpg"
        },
        "summary": "<p>When she was young, Yoo Da Mi was forced to move in with her grandmother. Unknowingly, it was then when her father disappeared from her life, and since then, she had always thought that her father was dead. As time wore on, her mother became one of the country's best-selling authors, but due to this, she had never found the time to be with her daughter. Suddenly, one day, Da Mi accidentally intercepts a phone call from her father and realizes that her mother has lied to her about her father's whereabouts. Angry, Da Mi decides to infuriate her mother by deciding to not to attend university and chooses to become an actress instead. Surprisingly, the first film she stars in is based on one of her mother's novels. Meanwhile, Ha Yeong Chan - her boss's step-brother - is a friend who secretly loves Da Mi though gets turned away by her.</p>",
        "updated": 1683603381,
        "_links": {
          "self": {
            "href": "https://api.tvmaze.com/shows/37282"
          },
          "previousepisode": {
            "href": "https://api.tvmaze.com/episodes/1485196"
          }
        }
      }
    },
    {
      "score": 0.6513799,
      "show": {
        "id": 36806,
        "url": "https://www.tvmaze.com/shows/36806/snow-queen",
        "name": "Snow Queen",
        "type": "Scripted",
        "language": "English",
        "genres": [
          "Adventure",
          "Family",
          "Fantasy"
        ],
        "status": "Ended",
        "runtime": 90,
        "averageRuntime": 90,
        "premiered": "2002-12-07",
        "ended": "2002-12-08",
        "officialSite": null,
        "schedule": {
          "time": "",
          "days": [
            "Saturday",
            "Sunday"
          ]
        },
        "rating": {
          "average": null
        },
        "weight": 36,
        "network": {
          "id": 50,
          "name": "Hallmark Channel",
          "country": {
            "name": "United States",
            "code": "US",
            "timezone": "America/New_York"
          },
          "officialSite": null
        },
        "webChannel": null,
        "dvdCountry": null,
        "externals": {
          "tvrage": null,
          "thetvdb": 96281,
          "imdb": "tt0210294"
        },
        "image": {
          "medium": "https://static.tvmaze.com/uploads/images/medium_portrait/222/556017.jpg",
          "original": "https://static.tvmaze.com/uploads/images/original_untouched/222/556017.jpg"
        },
        "summary": "<p>A young woman must journey through the seasons to rescue her boyfriend who has been kidnapped by the evil Snow Queen.</p>",
        "updated": 1639353441,
        "_links": {
          "self": {
            "href": "https://api.tvmaze.com/shows/36806"
          },
          "previousepisode": {
            "href": "https://api.tvmaze.com/episodes/1470517"
          }
        }
      }
    },
    {
      "score": 0.64171124,
      "show": {
        "id": 37320,
        "url": "https://www.tvmaze.com/shows/37320/summer-snow",
        "name": "Summer Snow",
        "type": "Scripted",
        "language": "Japanese",
        "genres": [
          "Drama",
          "Romance"
        ],
        "status": "Ended",
        "runtime": 60,
        "averageRuntime": 60,
        "premiered": "2000-07-07",
        "ended": "2000-09-15",
        "officialSite": null,
        "schedule": {
          "time": "21:00",
          "days": [
            "Friday"
          ]
        },
        "rating": {
          "average": null
        },
        "weight": 31,
        "network": {
          "id": 159,
          "name": "TBS",
          "country": {
            "name": "Japan",
            "code": "JP",
            "timezone": "Asia/Tokyo"
          },
          "officialSite": "https://www.tbs.co.jp/"
        },
        "webChannel": null,
        "dvdCountry": null,
        "externals": {
          "tvrage": null,
          "thetvdb": 84810,
          "imdb": "tt0281240"
        },
        "image": {
          "medium": "https://static.tvmaze.com/uploads/images/medium_portrait/159/398587.jpg",
          "original": "https://static.tvmaze.com/uploads/images/original_untouched/159/398587.jpg"
        },
        "summary": "<p>A love story between a young man who has been forced to grow up too quickly, and a young woman with an ailment that has placed restrictions on her life. Natsuo has been looking after his younger brother and sister since the death of their parents. He has also been running the family bicycle shop. Yuki becomes the only person in the world in whom he can confide. For Yuki, Natsuo becomes the catalyst that has her trying to break out of her cocoon.</p>",
        "updated": 1615604734,
        "_links": {
          "self": {
            "href": "https://api.tvmaze.com/shows/37320"
          },
          "previousepisode": {
            "href": "https://api.tvmaze.com/episodes/1485793"
          }
        }
      }
    },
    {
      "score": 0.641129,
      "show": {
        "id": 12393,
        "url": "https://www.tvmaze.com/shows/12393/snow-white",
        "name": "Snow White",
        "type": "Scripted",
        "language": "Korean",
        "genres": [
          "Drama",
          "Comedy",
          "Romance"
        ],
        "status": "Ended",
        "runtime": 60,
        "averageRuntime": 60,
        "premiered": "2004-03-15",
        "ended": "2004-05-04",
        "officialSite": "http://www.kbs.co.kr/drama/snowhite/",
        "schedule": {
          "time": "21:55",
          "days": [
            "Monday",
            "Tuesday"
          ]
        },
        "rating": {
          "average": null
        },
        "weight": 31,
        "network": {
          "id": 128,
          "name": "KBS2",
          "country": {
            "name": "Korea, Republic of",
            "code": "KR",
            "timezone": "Asia/Seoul"
          },
          "officialSite": null
        },
        "webChannel": null,
        "dvdCountry": null,
        "externals": {
          "tvrage": null,
          "thetvdb": 244591,
          "imdb": "tt1186855"
        },
        "image": {
          "medium": "https://static.tvmaze.com/uploads/images/medium_portrait/42/105688.jpg",
          "original": "https://static.tvmaze.com/uploads/images/original_untouched/42/105688.jpg"
        },
        "summary": "<p>Ma Young Hee has been in love with her best friend Han Jin Woo since the first time they met in high school. Han Jin Woo, on the other hand, does not see Young Hee's love for him and blindly goes out with other girls in front of her. On a trip to Japan, Young Hee meets a young man on the street who takes her back to his apartment since she is drunk. Young Hee accuses this man of raping her even though he didn't, but before she can rightfully press charges, he runs away as the government officials of Japan chase him down for an expired visa. Upon returning to Korea, Jin Woo stops by her apartment and introduces his younger brother, Han Sun Woo who just happens to be the young man she met in Japan. Jin Woo asks if Sun Woo may stay at her place, and Young Hee accepts out of love for Jin Woo. The story unfolds as a sudden high school classmate of Young Hee, Jang Hee Won, begins to court Jin Woo.</p>",
        "updated": 1463583608,
        "_links": {
          "self": {
            "href": "https://api.tvmaze.com/shows/12393"
          },
          "previousepisode": {
            "href": "https://api.tvmaze.com/episodes/591202"
          }
        }
      }
    },
    {
      "score": 0.64034295,
      "show": {
        "id": 14031,
        "url": "https://www.tvmaze.com/shows/14031/snow-babies",
        "name": "Snow Babies",
        "type": "Documentary",
        "language": "English",
        "genres": [
          "Nature"
        ],
        "status": "Ended",
        "runtime": 60,
        "averageRuntime": 60,
        "premiered": "2012-12-19",
        "ended": "2012-12-19",
        "officialSite": "http://www.bbc.co.uk/programmes/p0118wxq",
        "schedule": {
          "time": "22:00",
          "days": [
            "Wednesday"
          ]
        },
        "rating": {
          "average": null
        },
        "weight": 30,
        "network": {
          "id": 12,
          "name": "BBC One",
          "country": {
            "name": "United Kingdom",
            "code": "GB",
            "timezone": "Europe/London"
          },
          "officialSite": "https://www.bbc.co.uk/bbcone"
        },
        "webChannel": null,
        "dvdCountry": null,
        "externals": {
          "tvrage": null,
          "thetvdb": 265006,
          "imdb": null
        },
        "image": {
          "medium": "https://static.tvmaze.com/uploads/images/medium_portrait/48/120185.jpg",
          "original": "https://static.tvmaze.com/uploads/images/original_untouched/48/120185.jpg"
        },
        "summary": "<p>The heart-warming tale of a special group of baby animals born in some of the coldest and harshest places on Earth. We follow the ups and downs of impossibly cute yet plucky baby emperor penguins, snow monkeys, polar bears, arctic foxes, reindeer and otters and find out just what it takes to survive the first year of life in a world of snow and ice, with a little help from family and friends.</p>",
        "updated": 1503125833,
        "_links": {
          "self": {
            "href": "https://api.tvmaze.com/shows/14031"
          },
          "previousepisode": {
            "href": "https://api.tvmaze.com/episodes/646568"
          }
        }
      }
    },
    {
      "score": 0.61050516,
      "show": {
        "id": 39463,
        "url": "https://www.tvmaze.com/shows/39463/snow-men",
        "name": "Snow Men",
        "type": "Documentary",
        "language": "English",
        "genres": [],
        "status": "Ended",
        "runtime": 60,
        "averageRuntime": 60,
        "premiered": "2010-10-29",
        "ended": "2010-11-12",
        "officialSite": null,
        "schedule": {
          "time": "22:00",
          "days": [
            "Friday"
          ]
        },
        "rating": {
          "average": null
        },
        "weight": 19,
        "network": {
          "id": 66,
          "name": "Discovery Channel",
          "country": {
            "name": "United States",
            "code": "US",
            "timezone": "America/New_York"
          },
          "officialSite": "https://www.discovery.com/"
        },
        "webChannel": null,
        "dvdCountry": null,
        "externals": {
          "tvrage": null,
          "thetvdb": 202201,
          "imdb": null
        },
        "image": {
          "medium": "https://static.tvmaze.com/uploads/images/medium_portrait/377/942550.jpg",
          "original": "https://static.tvmaze.com/uploads/images/original_untouched/377/942550.jpg"
        },
        "summary": "<p>Following the people who labor to reopen dangerous roads that were closed due to extreme weather along Utah's Cottonwood Canyon.</p>",
        "updated": 1637518329,
        "_links": {
          "self": {
            "href": "https://api.tvmaze.com/shows/39463"
          },
          "previousepisode": {
            "href": "https://api.tvmaze.com/episodes/1557421"
          }
        }
      }
    }
  ]
});


// Simula una respuesta con error desde la API
mockHome.onGet('/api/didsTable/error').reply(500, {
  error: 'Error de la API dids mockeada didsTable',
});


export default mockHome;
