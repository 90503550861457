import React, { useState } from 'react';
import { motion } from "framer-motion";
import { BsArrowRightShort, BsPlusLg } from "react-icons/bs";
import Hotkeys from 'react-hot-keys';

export default function TexareaFields({ setInput, onSubmit }) {
  const [textareaError, setTextareaError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [count, setCount] = useState(0);
  const [maximum] = useState(1000);

  const handleChange = (event) => {
    event.preventDefault();
    const textarea = document.getElementById('textareaField').innerHTML;
    setCount(textarea.length);
  };

  const handleTextarea = (e) => {
    e.preventDefault();
    const textarea = document.getElementById('textareaField').innerHTML;
    setInput('textarea',textarea);
    
    const length = textarea.length;
    
    if (length < 10) {
      setErrorMsg("Text must be at least 10 characters");
      setTextareaError(true);
      console.log(textarea, errorMsg);
    } else if (length > maximum) {
      setErrorMsg("The text must have a maximum of 1000 characters");
      setTextareaError(true);
      console.log(textarea, errorMsg);
    } else {
      setTextareaError(false);
      setErrorMsg('');
      console.log(textarea, 'textarea correcto');
      onSubmit(e);
    }
    
  };

  const onKeyDown = (keyName, e, handle) => {
    e.preventDefault();
    switch(keyName){
      case "enter":handleTextarea(e);break;
      default: return false;
    }
    return false;
  };

  return (
    <div className="textarea-container" autoComplete="off">
      <motion.div
        className="col-md-6 offset-md-3"
        initial={{ y: "50vh" }}
        animate={{ y: 0 }}
        transition={{ stiffness: 150 }}
      >
        <Hotkeys 
          keyName="enter"
          onKeyDown={onKeyDown.bind(this)}
        >
          <h1>Tell us about the problem, we are here to help:</h1>
          <p>Write as detailed as possible:</p>
          <div className="containerInput">
            <span className="icon"><BsPlusLg /></span>
            <div id="contenedor">
              {count > 100 && <p className={textareaError ? 'count error' : 'count' } >{count}/{maximum} </p> }
              {count > 100 ?
                <div id="textareaField" className={textareaError ? 'error countField' : 'countField' } onInput={handleChange} contentEditable></div>
              :
                <div id="textareaField" className={textareaError ? 'error' : '' } onInput={handleChange} contentEditable></div>
              }
              
             
            </div>
           
          </div>
          <p className="error">{textareaError ? errorMsg : '' }</p>
          <div className="btn-container">
            <button className="btnSend" onClick={handleTextarea} ><BsArrowRightShort /></button>
            <p className='enter'>press Enter</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none" style={{marginTop: '20px'}}>
              <path d="M17.1818 10.5V11.1682H17.85V10.5H17.1818ZM0 10.5L6.68182 14.3577V6.64225L0 10.5ZM16.5136 0V10.5H17.85V0H16.5136ZM17.1818 9.83182H6.01364V11.1682H17.1818V9.83182Z" fill="white"/>
            </svg>
          </div>
        </Hotkeys>
      </motion.div>
    </div>
  );
}