import React from "react";
import { motion } from "framer-motion";


export default function BlankStart() {

  return (
    <div>
      <motion.div
        className="col-md-6 offset-md-3"
        initial={{ y: "50vh" }}
        animate={{ y: 0 }}
        transition={{ stiffness: 150 }}
      >
      </motion.div>
    </div>
  );
}