import React, { useState, useEffect } from 'react';
import  '../../mocks/apiHomeMock'; //necesarion para mockear la api
import  './insights.scss';
import { BsArrowRightShort } from "react-icons/bs";

const UsefulInsights = () => {
  const [text, setText] = useState(
    `<p className='InsightDetails bodyS'>You have </p> <p className='InsightsText bodyS'> 3104 </p> <p className='InsightDetails bodyS'>active card ID. </p>`
  );

  const format = amount => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,');
  };

  useEffect(() => {
    const updateText = () => {
      // Aquí puedes agregar la lógica para obtener el nuevo texto dinámico.
      // Por ejemplo, puedes hacer una petición a una API para obtener los datos actualizados.
      const numRandom = Math.floor(Math.random() * 4);
      //const numRandom = 2;
      switch (numRandom) {
        case 0:
          setText(`<p className='InsightDetails bodyS'>Today's balance is </p> <p className='InsightsText'>$${format(23499)}</p>`);
          break;
        case 1:
          setText(`<p className='InsightDetails bodyS'>Today he had </p> <p className='Insights bodyS'>12</p> <p className='InsightDetails bodyS'>calls made.</p>`);
          break;
        case 2:
          setText(`<p className='InsightDetails bodyS'>There are </p> <p className='InsightsText bodyS'>12345</p> <p className='InsightDetails bodyS'>active DIDS in your account.</p>`);
          break;
        case 3:
          setText(`<p className='InsightDetails bodyS'>You have </p> <p className='Insights bodyS'>5</p> <p className='InsightDetails bodyS'>pending tickets to answer.</p>`);
          break;
        default:
          setText(`<p className='InsightDetails bodyS'>You have </p> <p className='InsightsText bodyS'>3104</p> <p className='InsightDetails bodyS'>active card ID.</p>`);
          break;
      }
    };

    // Actualizar el texto cada 10 minutos (600000 milisegundos)
    const interval = setInterval(updateText, 600000);

    // Limpia el interval cuando el componente se desmonta para evitar fugas de memoria
    return () => clearInterval(interval);
  }, []);

  return (
    <div className='InsightsContainer'>
      <div className='InsightsRow'>
          <div className='InsightInformation' dangerouslySetInnerHTML={{ __html: text }} >
          </div>
        
      </div>
      <button className="btnNextInsights"  ><BsArrowRightShort /></button>
    </div>
  )
}

export default UsefulInsights