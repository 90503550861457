// Header.js

import React, { createContext, useState } from 'react';
import  '../styles/header.scss';
import CardSlideTop from '../../../components/cardSlideTop/cardSlideTop';

import Selector from '../components/Selector';

export const DIDContext = createContext(null);

export function Header() {
  const [didAddisVisible, setDIDisVisible] = useState(false);

  return (
    <header className="Header">
      <DIDContext.Provider value={{
        didAddisVisible:didAddisVisible,
        setDIDisVisible:setDIDisVisible,
      }}>
          <CardSlideTop/>
          <Selector/>
      </DIDContext.Provider>
      
    </header>
  );
}