
import React, { Component  } from 'react';
import PropTypes from 'prop-types';
import Hotkeys from 'react-hot-keys';
import { Header } from '../components/Header';
import { Sidebar } from '../components/Sidebar';
import { Content } from '../components/Content';
import ModalTicket from '../../../components/modalTicket/modalTicket';
import logo2 from '../../../images/logo2.png';
import  '../styles/default.scss';

class DefaultTemplate extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired
  };

  linkTab = (url) => {
    console.log("/"+url);
  }

  onKeyUp(keyNm, e, handle) {
    console.log(keyNm, "onKeyUp");
  }
  onKeyDown(keyName, e, handle) {
    e.preventDefault();
    //console.log(keyName, "onKeyDown");
    switch(keyName){
      case "alt+h":this.linkTab('home');break;
      case "alt+m":this.linkTab('minutes');break;
      default: return false;
    }
    return false;
  }

  render() {
    const { children, isOpenModal, handleOpenModal, handleCloseModal, contentModal } = this.props;
    let s_width = window.screen.width;
    var _class = "DefaultTemplate";
    return (
      <div className={_class} width={s_width}>
        <Hotkeys 
          keyName="alt+h,alt+m,alt+g,alt+p,alt+e,alt+c,alt+s,alt+b"
          onKeyDown={this.onKeyDown.bind(this)}
          onKeyUp={this.onKeyUp.bind(this)}
        >
          <ModalTicket isOpenModal={isOpenModal} handleCloseModal={handleCloseModal}>{contentModal}</ModalTicket>
          { Number(s_width) <= 1000 ? <div className="noMobile">
            <div className="conten">
              <img src={logo2} className="logo" alt="logo" />
              <p>Dashboard Mobile en construcción</p>
            </div>
            
          </div> :
          <div className="rowBody">
            <div className="colBody1">
              <Sidebar />
            </div>
            <div className="colBody2"> 
              <Header/>
              <Content children={children} handleOpenModal={handleOpenModal} />
              </div> 
            </div>
          }

        </Hotkeys>
      </div>
    );
  }
}

export default DefaultTemplate;