import React, { useState } from 'react';
import './cardProfile.scss';
import RoundPhoto from '../roundPhoto/roundPhoto';

export default function CardProfile(props) {
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  const cardClassName = `${isActive ? 'cardProfile activeCard' : 'cardProfile'} ${props.className || ''}`;

  return (
    <div className={cardClassName} onClick={e => {toggleActive(); props.clickActive(e)}}>
        <div className="contentCard">
            <RoundPhoto name={props?.data?.name} image={props?.data?.image} />
            <div className='titleCard'>
                <span className="title">Full name</span> 
                <h3>{props?.data?.name}</h3>
                <h3>{props?.data?.lastName}</h3>
                <span className="subtitle">{props?.data?.nickName}</span>
            </div>
        </div>
    </div>
  )
}
