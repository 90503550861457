import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import AuthService from '../services/AuthService';

import TypeFormLogin from "../../../components/typeForm/typeFormLogin";
import BlankStart from "../../../components/typeForm/login/blankStart";
import EmailFields from "../../../components/typeForm/login/email";
import PasswordField from "../../../components/typeForm/login/password";


import  '../styles/auth.scss';

const LoginForm = () => {
  const authService = new AuthService();
  const [fields, setFields] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [msj, setMsj] = useState('');
  //const [error, setError] = useState(false);
  const navigate = useNavigate();

  const setInput = (type, value) => {
    switch (type) {
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
        break;
    }
  }

  const handleLogin = (event) => {
    event.preventDefault(); // Evita que se actualice la página al enviar el formulario
    console.log(email, password)
    authService.login(email, password)
      .then((response) => {
        // Maneja la respuesta exitosa del inicio de sesión
        console.log('Inicio de sesión exitoso');
        setMsj('Inicio de sesión exitoso');
        //setError(false);
        navigate("/home");
      })
      .catch((error) => {
        // Maneja el error del inicio de sesión
        console.error('Error en el inicio de sesión', error);
        //setError(true);
        setMsj('Error en el inicio de sesión')
      });
  };

  return (
    <div className="auth-container">
      <TypeFormLogin onSubmit={handleLogin} setFields={setFields} fields={fields} >
        <BlankStart />
        <EmailFields setInput={setInput} setFields={setFields} />
        <PasswordField  setInput={setInput} setFields={setFields} onSubmit={handleLogin} ErrorMsj={msj} />
      </TypeFormLogin>
    </div>
  );
};

export default LoginForm;